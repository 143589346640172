/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "headline_colors_default": "",
  "headline_variant_default": "",
  "headline_size_default": "text-headline-xs sm:text-headline-sm",
  "slide_caption_variant_default": "uppercase",
  "slide_caption_colors_default": "text-logga-700",
  "slide_caption_size_default": "text-headline-xs !font-normal my-1",
  "slide_description_colors_default": "",
  "slide_description_variant_default": "",
  "slide_description_size_default": "text-headline-xs sm:text-headline-sm",
  "slide_group_variant_default": "[counter-reset:most-read] flex-nowrap flex-row flex overflow-x-auto overflow-y-hidden scrollbar-thin snap-x !scrollbar",
  "slide_group_colors_default": "scrollbar-thumb-logga-500",
  "slide_group_size_default": "gap-3 pb-4 scrollbar-h-1.5",
  "slide_image_variant_default": "after:absolute after:bottom-0 after:content-[counter(most-read)] after:flex after:items-center after:justify-center after:right-1.5 after:rounded-full after:translate-y-1/2 relative [&_img]:rounded-sm after:!font-medium",
  "slide_image_colors_primary": "after:bg-logga-500 after:text-white [&_picture]:bg-transparent [&_img]:bg-transparent",
  "slide_image_size_default": "after:h-9 after:w-9 after:text-headline-sm mb-2",
  "slide_variant_default": "[counter-increment:most-read] flex-col flex grow relative snap-center",
  "slide_colors_default": "",
  "slide_size_default": "gap-1 min-w-61 sm:min-w-75",
  "colors_default": "bg-dodger-blue-300 wings-dodger-blue-300",
  "variant_default": "flex-col flex overflow-hidden wings",
  "size_default": "gap-2 sm:gap-3 py-4 w-[calc(100%_+_1rem)] sm:w-full"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "MostRead");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;